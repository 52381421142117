html {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Lato;
  src: url('./assets/fonts/lato-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/lato-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: Lato;
  src: url('./assets/fonts/lato-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/lato-bold-webfont.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url('./assets/fonts/lato-italic-webfont.woff2') format('woff2'),
       url('./assets/fonts/lato-italic-webfont.woff') format('woff');
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url('./assets/fonts/lato-bolditalic-webfont.woff2') format('woff2'),
       url('./assets/fonts/lato-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}